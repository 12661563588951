<!--党员信息-->
<template>
  <div class="pair-to-help">
    <!-- 图片 -->
    <img src="./imgs/bg1.png" style="width:100%;" />
    <div class="pair-to-help-body">
      <!-- 搜索 -->
      <div class="post-myword">
        <span class="searchIcon"><img src="./imgs/search.png" alt="" /></span>
        <input
          type="text"
          name
          id="myword"
          placeholder="输入名称进行搜索"
          v-model="searchName"
        />
        <span @click="search" class="searchBtn">| 搜索</span>
      </div>
      <!-- 列表 -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        :offset="70"
        @load="onLoad"
      >
      <ul class="pair-to-help-list">
        <li v-for="(item, index) in infoList" :key="index" class="info">
          <div class="img">
            <img v-if="item.avatar" :src="item.avatar" />
            <img v-else src="./imgs/avatar.png" />
          </div>
          <div class="republicer">
            <div>
              <span style="font-size: 18px; font-weight: 500">{{
                item.realName
              }}</span>
              {{ item.sexName }}
            </div>
            <div>{{item.deptName}}</div>
            <div>手机：<a :href="'tel:'+item.phone">{{ item.phone }}</a></div>
          </div>
        </li>
      </ul>
      </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { getPartyMemberInfor } from '@/api/partyMemberInfor';
export default {
  data() {
    return {
      active: 0,
      activeType: 'all',
      infoList: [],
      pairSuccess: '0',
      currentPage: 1,
      loading: false,
      finished: false,
      error: false,
      refreshing: false,
      searchName: '',
      bingInfo: {},
      showCard: false,
      getRecordStatus: false,
    };
  },
  watch: {
    infoList() {
      if (this.infoList.length == 0) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
    searchName() {
      if (this.searchName == '' && this.infoList.length == 0) {
        this.currentPage = 1;
        this.getRecordStatus = true
        this.getMorePage();
      }
    },
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.push({ name: 'mainPage' });
    },
    //刷新数据
    onRefresh() {
      // 清空列表数据

      this.infoList = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true;
      this.currentPage = 1;
      this.onLoad();
    },
    //请求更多数据
    getMorePage() {
      this.getRecordStatus = true;
      let data = null
      var searchN = this.searchName
      data = {
          current: this.currentPage,
          size: 5,
          realName: searchN,
          status: 1,
      }
      getPartyMemberInfor({
        ...data
      })
        .then((res) => {
          if (res.success) {
            //加载完成
            this.showCard = true;
            this.loading = false;
            this.refreshing = false;
            this.currentPage++; //一定要先进行页数加加
            if (res.data.records.length === 0) {
              this.finished = true;
              return false;
            }
            //用concat去拼接
            let arr = [...this.infoList, ...res.data.records];
            this.infoList = arr;
            this.getRecordStatus = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.getRecordStatus = false;
        });
    },

    //搜索
    search() {
      this.finished = false;
      this.infoList.splice(0, this.infoList.length)
      this.currentPage = 1
      this.getMorePage()
    },

    //加载更多数据
    onLoad() {
      if (!this.getRecordStatus) {
        console.log("onLoad: ",this.getRecordStatus);
        this.getMorePage();
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
$px: 1rem/37.5;
.pair-to-help {
  .pair-to-help-body {
    .post-myword {
      width: 100%;
      padding: 16px;
      font-size: 14px;
      position: relative;
      .searchIcon {
        position: absolute;
        left: 25px;
        top: 33%;
      }
      input {
        width: 70%;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #eeeeee;
        padding-left: 30px;
        padding-right: 50px;
      }
      .searchBtn {
        position: absolute;
        right: 60px;
        top: 36%;
      }
    }
    .pair-to-help-list {
      padding: 0 16px;
      width: 100%;
      box-sizing: border-box;
      .info {
        background: #fff url(./imgs/bg2.png) no-repeat;
        padding: 24px 12px;
        display: flex;
        box-sizing: border-box;
        margin-bottom: 12px;
        background-size: 100%;
        .img {
          width: 75px;
          height: 75px;
          margin-right: 12px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .republicer {
          height: 75px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-size: 14px;
          color: #333;
        }
      }
    }
  }
}
</style>
